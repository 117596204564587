export const steps = [
  {
    title:
      "Escolha o carro ideal para você e apresentamos a melhor oferta de financiamento.",
    number: 1,
  },
  {
    title:
      "Simule seu financiamento e veja as condições personalizadas para você.",
    number: 2,
  },
  {
    title:
      "Fale com um de nossos parceiros ou especialistas e feche o contrato.",
    number: 3,
  },
]
