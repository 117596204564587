//Components
import { Text } from "@mobi/ds"
import CarouselSlider from "./components/CarouselSlider"

//Style
import "./index.scss"

const Steps = () => {
  return (
    <section className="steps">
      <div className="steps__container">
        <Text size="lg" weight="700" className="steps__title">
          <strong>seu veículo financiado em poucos passos</strong>
        </Text>
        <CarouselSlider />
      </div>
    </section>
  )
}

export default Steps
