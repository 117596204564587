export const carouselItems = [
  {
    file: "portoseguro-image",
    title: "porto seguro",
    text: "até 10% de desconto para proteger o seu carro",
  },
  {
    file: "planoauto-image",
    title: "plano auto",
    text: "25% de desconto para reparos na lataria",
  },
]
