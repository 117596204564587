// Components
import { Text } from "@mobi/ds"

// Styles
import "./index.scss"

const TITLE = "o icarros te ajuda a financiar seu próximo carro!"
const DESCRIPTION =
  "Faça uma simulação personalizada, escolha a entrada ideal e a quantidade de parcelas que mais combinam com o seu momento."

const Financing = () => {
  return (
    <section className="financing">
      <div className="financing__container">
        <Text as="h3" weight="400" size="xl" className="financing__title">
          {TITLE}
        </Text>
        <Text
          as="p"
          size="md"
          weight="500"
          className="financing__inside-description"
        >
          {DESCRIPTION}
        </Text>
      </div>
      <Text
        as="p"
        size="md"
        weight="500"
        className="financing__outside-description"
      >
        {DESCRIPTION}
      </Text>
    </section>
  )
}

export default Financing
