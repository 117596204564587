//Components
import { VehiclesIcon } from "@garantidos/components"

import { Button, Text } from "@mobi/ds"
// Tracking
import analytics from "@mobi/libraries/analytics"

//Style
import "./index.scss"
//Utils
import { goToForm } from "utils/forms"

import CarouselSlider from "./components/CarouselSlider"

const Conditions = () => {
	return (
		<section className="conditions">
			<div className="conditions__container">
				<div>
					<Text size="lg" weight="700" className="conditions__title">
						<strong>
							financiar seu veículo com o icarros é sempre a melhor opção
						</strong>
					</Text>
					<Text size="md" weidht="400" className="conditions__description">
						Aqui você tem as vantagens do icarros com a segurança do Itaú
					</Text>
				</div>
				<CarouselSlider />
				<div className="conditions__button-wrapper">
					<Button
						className="conditions__button"
						variant="primary"
						type="button"
						fluid
						rightIcon={<VehiclesIcon id="icon-arrow-right" />}
						onClick={() => {
							const webElement = {
								buttonName: "simularAgoraCondicoes",
								buttonText: "simular agora",
								location: "Section conditions"
							}
							analytics.track("buttonClickSegment", webElement)
							goToForm()
						}}
					>
						simular agora
					</Button>
				</div>
			</div>
		</section>
	)
}

export default Conditions
