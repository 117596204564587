export const conditionsItems = [
  {
    icon: "icon-vehicle-ad",
    title: "parceiros icarros",
    text: "Compre seu veículo em centenas de lojas e concessionárias parceiras disponíveis para financiamento.",
  },
  {
    icon: "icon-steering-wheel",
    title: "o melhor veículo para você",
    text: "No icarros você tem muito mais chances de encontrar o modelo ideal entre centenas de modelos e marcas. ",
  },
  {
    icon: "icon-security",
    title: "condições exclusivas",
    text: "Financie até 90% do valor do veículo e parcele em até 60 meses.",
  },
  {
    icon: "icon-armored-car",
    title: "economize com benefícios",
    text: (
      <>
        Você tem benefícios <br />
        exclusivos ao financiar <br />
        com o icarros.
      </>
    ),
  },
]
