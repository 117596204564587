import { HOMEPATH } from "gatsby-env-variables"
import { useEffect } from "react"

//Component
import Layout from "components/Layout"
import Hero from "./components/Hero"
import Financing from "./components/Financing"
import Conditions from "./components/Conditions"
import Steps from "./components/Steps"
import Benefits from "./components/Benefits"
import Faq from "./components/Faq"
import Header from "components/Header"
import Footer from "components/Footer"

// Tracking
import analytics from "@mobi/libraries/analytics"

const Home = () => {
  useEffect(() => {
    analytics.track("pageLoad", {})
  }, [])

  return (
    <>
      <Layout className="-home">
        <Header
          prevUrl={HOMEPATH}
          dynamicLogo={"itau"}
          linkLogoUrl={HOMEPATH}
          isActiveButtonAccess={false}
          mobileInfosApp={false}
          isHome={true}
        />
        <Hero />
        <Financing />
        <Conditions />
        <Steps />
        <Benefits />
        <Faq />
        <br />
        <br />
        <Footer />
      </Layout>
    </>
  )
}

export default Home
