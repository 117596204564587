// Components
import { Text } from "@mobi/ds"
import { AutoWidthCarousel } from "components/AutoWidthCarousel"

//Style
import "./index.scss"

// Hooks
import { useMediaQuery } from "@mobi/hooks"

//Data
import { steps } from "./dataContent"

const CarouselSlider = () => {
  const stepsAsCarousel = useMediaQuery(1200)

  return (
    <div className="steps__list">
      <AutoWidthCarousel
        gap={stepsAsCarousel ? 8 : 28}
        containerSelector=".steps__list"
        arrows={false}
      >
        {steps.map(({ number, title }) => (
          <div key={number} className="steps__item">
            <div className="steps__item-number">
              <span>{number}</span>
            </div>
            <Text size="lg" weight="400" className="steps__item-title">
              {title}
            </Text>
          </div>
        ))}
      </AutoWidthCarousel>
    </div>
  )
}

export default CarouselSlider
