// Components
import { VehiclesIcon } from "@garantidos/components"

import { Accordion, Button, Text } from "@mobi/ds"

//Data
import { listItems } from "./dataContent"
//Style
import "./index.scss"
// Utils
import { goToForm } from "utils/forms"

const Faq = () => {
	return (
		<section className="faq">
			<div className="faq__container">
				<Text as="h2" size="xxl" weight="700">
					tire todas suas dúvidas sobre financiamento de veículos
				</Text>
				<br />
				<div role="presentation" className="faq__wrapper">
					<Accordion items={listItems} />
				</div>
				<br />
				<br />
				<div className="faq__button-container">
					<Button
						fluid
						className="faq__button"
						style={{ marginBottom: 30 }}
						variant="primary"
						rightIcon={<VehiclesIcon id="icon-arrow-right" />}
						onClick={() => {
							goToForm()
						}}
					>
						simular agora
					</Button>
				</div>
			</div>
		</section>
	)
}

export default Faq
