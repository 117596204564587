//Components
import If from "@mobi/components/If"
import { Input } from "@mobi/ds"

//Hooks
import usePersonalData from "./usePersonalData"
import Loader from "components/Loader"
import Sender from "components/Sender"

const PersonalData = () => {
	const { onSubmit, isLoading, formProps } = usePersonalData()

	const {
		control,
		handleSubmit,
		formState: { isValid }
	} = formProps

	const send = (values) => onSubmit(values)

	return (
		<>
			<If condition={isLoading} renderIf={<Loader variant="spinner-only" />} />
			<form onSubmit={handleSubmit(send)}>
				<Input.Cpf
					label="cpf"
					name="documentNumber"
					autoComplete="on"
					control={control}
					pattern="[0-9\.\-]*"
				/>
				<Input.Name
					label="nome completo"
					name="fullName"
					autoComplete="on"
					control={control}
					validators={{ messages: { name: "insira o seu nome completo" } }}
				/>
				<Input.Email
					label="e-mail"
					name="email"
					autoComplete="on"
					control={control}
				/>

				<Input.Cellphone
					label="celular"
					name="phone"
					autoComplete="on"
					control={control}
					validators={{ messages: { cellphone: "número inválido" } }}
				/>

				<Sender isNextDisabled={!isValid} hasPrev={false} nextLabel="simular" />
			</form>
		</>
	)
}

export default PersonalData
