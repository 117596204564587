//Components
import { Text, Link } from "@mobi/ds"
import PersonalData from "../PersonalData"

//Style
import "./index.scss"

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero__background"></div>
      <div className="hero__container">
        <div className="hero__title">
          <Text as="h1" size="xxl" weight="700">
            financie seu próximo carro com icarros
          </Text>
          <Text size="md">
            Quer financiar um carro novo ou usado? Faça uma simulação e veja o
            valor das parcelas na hora.
          </Text>
        </div>
      </div>
      <div className="hero__container hero__container-box">
        <div className="hero__box">
          <Text as="h2" size="xxl" weight="400">
            preencha seus dados e simule
          </Text>
          <div className="hero__form">
            <PersonalData />
            <br />

            <Text as="p" size="xs" className="hero__description">
              Ao clicar em simular, você autoriza o icarros a tratar seus dados
              para envio de ofertas e promoções em seu e-mail e telefone, bem
              como, colete e envie seus dados para os bancos parceiros conforme
              nossos o{" "}
              <Link
                href="https://privacidade.icarros.com.br/"
                target="_blank"
                rel="noreferrer"
                size="xs"
                className="hero__external-links"
              >
                Termos de Uso
              </Link>{" "}
              e{" "}
              <Link
                href="https://privacidade.icarros.com.br/politica-de-privacidade"
                target="_blank"
                rel="noreferrer"
                size="xs"
                className="hero__external-links"
              >
                Política de Privacidade.
              </Link>
            </Text>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
