import { useState, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"

// Services
import services from "services"
import { updateToken, clearToken } from "services/auth"

// Utils
import {
  saveOnSessionStorage,
  removeFromSessionStorage,
  removeFromSessionStorageWithoutPrefix,
} from "utils/storage"
import getQueryStringFromObject from "utils/getQueryStringFromObject"
import { getUrlParamAll, getUUIDFromUrl, resetFocus } from "utils/browser"
import { fillForm } from "utils/forms"
import getAllTrackingData from "utils/getAllTrackingData"

// Contexts
import { Context } from "contexts/simulation"

// Tracking
import analytics from "@mobi/libraries/analytics"

//Hooks
import useRecaptchaToken from "hooks/useRecaptchaToken"
import { filtersSavedOnSessionStorage } from "pages/Form/steps/Deal/useDeal"

// TODO: Substituir condicional por KEY do erro quando estiver implementado na API
const INVALID_EMAIL_START_MESSAGE_OF_ERROR =
  "Identificamos que este e-mail é inválido, poderia verificar"

const usePersonalData = () => {
  const { setSimulation } = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)

  const { token } = useRecaptchaToken()

  const formProps = useForm({
    mode: "onChange",
    defaultValues: {
      fullName: "",
      email: "",
      documentNumber: "",
      phone: "",
    },
  })

  const uuid = getUUIDFromUrl()

  useEffect(() => {
    if (!uuid) {
      clearToken()
      ;[
        "phone",
        "simulation",
        "initialPrice",
        "finalPrice",
        "initialYear",
        "finalYear",
        "isClosedFlow",
        "sellerStreetName",
        ...(filtersSavedOnSessionStorage ? filtersSavedOnSessionStorage : ""),
      ].forEach((item) => removeFromSessionStorage({ name: item }))
    }
    removeFromSessionStorageWithoutPrefix({ name: "has-send" })
  }, [])

  useEffect(() => {
    resetFocus()

    const getSimulation = async () => {
      setIsLoading(true)
      const response = await services.personalData.getSimulation()
      if (response) {
        fillForm({
          simulation: {
            ...response?.lead,
          },
          formProps,
        })

        setIsLoading(false)
        return response
      }

      setIsLoading(false)
    }

    if (uuid) {
      getSimulation()
    }
  }, [])

  const onNext = (data) => {
    const { leadUUID } = data
    const currentUuid = getUUIDFromUrl()
    const uuid = currentUuid ? currentUuid.value : leadUUID
    const currentParams = getUrlParamAll()
    const query = getQueryStringFromObject({
      ...currentParams,
      leadUUID: uuid,
    })

    navigate(`/formulario/${query}`)
  }

  const updateLocalStep = ({ payload, updatedData }) => {
    const { auth, nextFormStep } = updatedData
    if (!uuid) {
      updateToken(auth)
    }
    saveOnSessionStorage({ name: "phone", value: payload.phone })
    setSimulation({
      ...updatedData,
      currentFormStep: nextFormStep,
    })

    onNext(updatedData)
  }

  const prepareToTrack = () => {
    const fieldsInfo = Object.entries(formProps.getValues()).map(
      (entry) => entry
    )
    const stepContext = {
      stepName: "personalData",
      stepNumber: 1,
    }
  }

  const onSubmit = async (payload) => {
    setIsLoading(true)
    const trackingData = await getAllTrackingData().catch((err) =>
      console.log(err)
    )

    const createdSimulation = await services.personalData
      .createSimulation(payload, trackingData, token)
      .catch((error) => {
        const isEmailInvalid = (
          error?.response?.data?.message || ""
        ).startsWith(INVALID_EMAIL_START_MESSAGE_OF_ERROR)

        if (isEmailInvalid) {
          formProps.setError("email", { type: "email" })
        }

        setIsLoading(false)
      })

    if (createdSimulation?.status === 200) {
      analytics.track("leadStart", {
        uuid: createdSimulation.data.leadUUID,
        ...payload,
      })
      updateLocalStep({ payload, updatedData: createdSimulation?.data })
      prepareToTrack()
    }
  }

  return {
    isLoading,
    formProps,
    stepName: "personal-data",
    onSubmit,
  }
}

export default usePersonalData
