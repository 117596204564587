// Components
import { Carousel, CarouselItem, Text } from "@mobi/ds"
import Img from "components/Img"

// Hooks
import { useMediaQuery } from "@mobi/hooks"

//Style
import "./index.scss"

//Data
import { carouselItems } from "./dataContent"

const CarouselSlider = () => {
  const benefitsAsSlider = useMediaQuery(900)

  return (
    <div className="carousel">
      <Carousel
        bullets={benefitsAsSlider}
        arrows={benefitsAsSlider}
        variant="slider"
        options={{
          gap: 0,
          keyboard: false,
          perView: benefitsAsSlider ? 1 : carouselItems.length,
          peek: 0,
        }}
        indentifier="benefits-carousel"
      >
        {carouselItems.map(({ file, text, title }) => (
          <CarouselItem key={file}>
            <div className="carousel__card">
              <Img file={file} className="carousel__image" />
              <div className="carousel__body">
                <Text
                  className="carousel__body-title"
                  as="p"
                  size="md"
                  weight="600"
                >
                  {title}
                </Text>
                <Text
                  className="carousel__body-text"
                  as="p"
                  size="md"
                  weight="600"
                >
                  {text}
                </Text>
              </div>
            </div>
          </CarouselItem>
        ))}
      </Carousel>
    </div>
  )
}

export default CarouselSlider
