// Components
import { VehiclesIcon } from "@garantidos/components"

import { Text } from "@mobi/ds"
// Hooks
import { useMediaQuery } from "@mobi/hooks"

//Data
import { conditionsItems } from "./dataContent"
//Style
import "./index.scss"
import { AutoWidthCarousel } from "components/AutoWidthCarousel"

const CarouselSlider = () => {
	const conditionsAsCarousel = useMediaQuery(1200)

	return (
		<div className="conditions__list">
			<AutoWidthCarousel
				gap={conditionsAsCarousel ? 16 : 32}
				containerSelector=".conditions__list"
				arrows={false}
			>
				{conditionsItems.map(({ icon, title, text }) => (
					<div key={title} className="conditions__item">
						<VehiclesIcon className="conditions__item-icon" id={icon} />
						<Text size="lg" className="conditions__item-title">
							{title}
						</Text>
						<Text as="p" size="md" className="conditions__item-text">
							{text}
						</Text>
					</div>
				))}
			</AutoWidthCarousel>
		</div>
	)
}

export default CarouselSlider
