export const listItems = [
  {
    title: `o que é "ano-modelo"?`,
    children:
      "Todo veículo possui o ano de fabricação e o ano modelo, geralmente apresentados no formato fabricação/modelo (2018/2019, por exemplo). Para o nosso financiamento você precisa olhar o ano-modelo.",
  },
  {
    title: "posso financiar uma moto?",
    children:
      "Para financiamento de motos, você pode procurar revendas parceiras do Itaú para essa finalidade.",
  },
  {
    title:
      "quais são os documentos necessários para fazer um financiamento de veículos com o Itaú?",
    children: (
      <>
        Para fazer um financiamento no Itaú, é necessário que o responsável pelo
        contrato tenha:
        <br />
        Em casos de usados: CNH, o documento do veículo e os dados do vendedor.
        <br />
        Em casos de 0km: CNH, nota fiscal do veículo e os dados da loja.
      </>
    ),
  },
  {
    title: "como funciona o financiamento no icarros?",
    children:
      'Ao encontrar o carro que você quer financiar, basta clicar no botão "simular financiamento" e seguir com o preenchimento das informações solicitadas, pois assim conseguiremos analisar o seu crédito com o banco Itaú. Em seguida, selecione as melhores parcelas que cabem no seu bolso e confira o resultado da análise de crédito que aparecerá na sua tela. Prontinho! Agora, é só salvar ou imprimir a proposta e levá-la até a loja. Ah, o voucher também será enviado para o seu e-mail de cadastro. Em alguns casos, o Itaú poderá entrar em contato para auxiliar no processo de contratação.',
  },
  {
    title: "quero saber se tenho crédito com o Itaú. Como eu faço?",
    children:
      'Aqui no icarros você consegue saber se tem crédito aprovado com o banco Itaú em instantes. Basta preencher os seus dados, escolher as melhores parcelas que cabem no seu bolso, selecionar o carro desejado e clicar em "análise de crédito". Pronto, já vai aparecer na tela se você tem crédito disponível.',
  },
  {
    title: "existe uma opção de financiamento sem entrada?",
    children:
      "Atualmente, não temos essa opção, mas oferecemos condições diferenciadas e personalizadas para você. Para descobrir, faça agora mesmo a sua simulação, preenchendo os dados no topo da página. É rápido, fácil e seguro.",
  },
  {
    title: "consigo usar meu carro como troca no financiamento?",
    children:
      "Para isso, é necessário entrar em contato com a loja na qual você encontrou o carro desejado e consultar se existe essa opção. Caso seja possível, simule aqui apenas o valor da diferença, por exemplo: a loja aceita o carro como troca no financiamento, o seu veículo atual vale R$ 40 mil e o carro desejado custa R$ 90 mil. Sendo assim, você vai simular apenas R$ 50 mil, que é o valor da diferença pelo seu novo carro.",
  },
  {
    title:
      "quero financiar um carro de uma pessoa física, não de uma loja/revenda, é possível? Como funciona?",
    children:
      "Ainda não temos essa opção. O financiamento é possível para ofertas de lojas e revendas. Para conferir condições diferenciadas e personalizadas para você, faça a simulação agora mesmo, de forma rápida e fácil.",
  },
]
