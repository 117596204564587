//Components
import { Text } from "@mobi/ds"
import CarouselSlider from "./components/CarouselSlider"

//Style
import "./index.scss"

const Benefits = () => {
  return (
    <section className="benefits">
      <div className="benefits__container">
        <Text size="xl" weight="700" className="benefits__title">
          <strong>aproveite seus benefícios</strong>
        </Text>
        <CarouselSlider />
      </div>
    </section>
  )
}

export default Benefits
